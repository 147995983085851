export const useCountryQuery = () => {
  return useQuery({
    queryKey: ['useCountryQuery'],
    queryFn: () => fetchByGet<Array<CountryObject>>('/v2/kyc/country/list', {}),
    staleTime: 5 * 60 * 1000,
  })
}

export const useStateListQuery = (region_id: globalThis.Ref<string | undefined>) => {
  return useQuery({
    queryKey: ['useStateListQuery', region_id],
    queryFn: () => fetchByGet<Array<CountryObject>>('/v2/kyc/state/list', { region_id: region_id?.value }),
    enabled: computed(() => !!region_id.value),
    staleTime: 10 * 60 * 1000,
  })
}

export const useFileMutation = () => {
  return useMutation({
    mutationFn: (params: FormData) => fetchByFile<[IFile]>('/v2/kyc/file/upload', params),
  })
}

export const useDictionaryQuery = () => {
  return useQuery({
    queryKey: ['useDictionaryQuery'],
    queryFn: () =>
      fetchByGet<Array<Record<string, Array<{ code: string; option: string }>>>>('/v2/kyc/custom/fields', {}, { injectToast: false }),
    enabled: false,
    staleTime: 5 * 60 * 1000,
  })
}

export const useAuthorizeMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (
      params: Record<'timestamp' | 'signature' | 'appKey' | 'appId' | 'appUrl' | 'appUid' | 'code', string> & {
        accountType: 'Individual' | 'Entity'
      },
    ) => {
      let $params = {
        timestamp: params.timestamp,
        signature: params.signature,
        'APP-KEY': params.appKey,
        'APP-ID': params.appId,
        'APP-URL': params.appUrl,
        'APP-UID': params.appUid,
        'REF-CODE': params.code,
        account_type: params.accountType,
      }
      return fetchByPost<{ access_token: string }>('/ex/authorize', $params, { injectToast: false })
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useCountryQuery', 'useDictionaryQuery', 'useStateListQuery'] })
    },
  })
}

export const useAccountCustomSupportQuery = () => {
  return useQuery({
    queryKey: ['useAccountCustomSupportQuery'],
    queryFn: () => fetchByGet<{ cs: string }>('/kyc/account/cs', {}),
  })
}

export const useFilePreviewMutation = () => {
  return useMutation({
    mutationFn: (file_preview: string) => fetchByPost<Blob>('/v2/kyc/file/preview', { file_preview }),
  })
}

export const useEmailMutation = () => {
  return useMutation({
    mutationFn: (email: string) => fetchByPost<any>('/kyc/registration', { email }),
  })
}

export const useIPQuery = () => {
  return useQuery({
    queryKey: ['useIPQuery'],
    queryFn: () =>
      fetch('https://ip2c.org/s')
        .then((response) => response.text())
        .then((response) => {
          const result = (response || '').toString()

          if (!result || result[0] !== '1') {
            throw new Error('unable to fetch the country')
          }

          return result.substr(2, 2)
        }),
  })
}
