<template>
  <section class="tw-flex-1 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-min-h-40 md:tw-min-h-96">
    <section v-if="isPending" class="tw-flex-1 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
      <!-- <slot v-if="$slots.loading" name="loading"></slot>
      <span v-else class="tw-loading tw-loading-infinity tw-loading-lg tw-relative -tw-top-10"></span> -->
      <div
        class="tw-w-full tw-h-full"
        :class="{
          'tw-max-w-80 tw-max-h-80': size === 'large',
          'tw-max-w-40 tw-max-h-40 tw-relative -tw-top-10': size === 'medium',
        }"
        ref="loadingRef"
      />
    </section>
    <section
      v-else-if="error"
      class="tw-flex-1 tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-6 md:tw-gap-10"
    >
      <h1 class="tw-uppercase tw-text-2xl tw-font-bold tw-text-yellow-500"> we are sorry</h1>
      <div class="tw-flex tw-flex-col tw-items-baseline tw-justify-center tw-gap-2 md:tw-gap-4 xl:tw-gap-6 tw-text-sm lg:tw-text-base">
        <div>
          <p v-if="error.code === '101100'">You are accessing Legend Trading services from {{ error.metadata?.country }}.</p>
          <p>{{ error.message }} </p>
        </div>
        <p>We apologize for the inconvenience.</p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="tw-h-14 tw-w-14 md:tw-h-20 md:tw-w-20 tw-fill-red-500 tw-relative tw-rotate-90"
      >
        <title>block-helper</title>
        <path
          d="M12,0A12,12 0 0,1 24,12A12,12 0 0,1 12,24A12,12 0 0,1 0,12A12,12 0 0,1 12,0M12,2A10,10 0 0,0 2,12C2,14.4 2.85,16.6 4.26,18.33L18.33,4.26C16.6,2.85 14.4,2 12,2M12,22A10,10 0 0,0 22,12C22,9.6 21.15,7.4 19.74,5.67L5.67,19.74C7.4,21.15 9.6,22 12,22Z"
        />
      </svg>
      <!-- <a class="tw-btn tw-btn-xs tw-btn-primary tw-text-base-100" @click="reload">{{ $t('kyc.Refresh') }}</a> -->
    </section>
    <section class="tw-flex-1 tw-flex tw-flex-col tw-w-full tw-h-full tw-relative" v-else-if="isSuccess">
      <div
        class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex-none tw-flex tw-justify-center tw-items-center tw-bg-white/20 tw-z-50"
        v-if="isFetching"
      >
        <!-- <slot v-if="$slots.loading" name="loading"></slot>
        <span v-else class="tw-loading tw-loading-dots tw-loading-lg"></span> -->
        <div
          class="tw-w-full tw-h-full"
          ref="loadingRef"
          :class="{
            'tw-max-w-80 tw-max-h-80': size === 'large',
            'tw-max-w-40 tw-max-h-40': size === 'medium',
          }"
        />
      </div>
      <slot />
    </section>
  </section>
</template>
<script setup lang="ts">
  const props = withDefaults(
    defineProps<{ isPending: boolean; error: any; isSuccess: boolean; isFetching?: boolean; size?: 'large' | 'medium' }>(),
    {
      size: 'medium',
    },
  )
  const animation = ref()
  const loadingRef = ref()
  onMounted(async () => {
    const [lottie, AnimationData] = await Promise.all([import('lottie-web'), import('@/assets/animation/loading.json')])
    const ele = lottie.default.loadAnimation({
      container: loadingRef.value as HTMLDivElement,
      renderer: 'svg',
      loop: true,
      animationData: AnimationData.default,
    })
    animation.value = ele
  })

  onUnmounted(() => {
    animation.value?.destroy?.()
    animation.value = {}
  })
  const router = useRouter()
  const route = useRoute()
  watchEffect(() => {
    if (props.error) {
      if (props.error.code === '303102') {
        router.push({
          ...route,
          query: {
            ...route.query,
            state: 303102,
          },
        })
      }
    }
  })

  const reload = () => {
    window.location.reload()
  }
</script>
