import { toast } from 'vue3-toastify'

export const useSign = () => {
  const route = useRoute()
  const { t } = useI18n()
  const { common } = useApi()
  const { mutateAsync, isSuccess: isSuccessAuth, isPending: isPendingAuth, error: errorAuth } = common.useAuthorizeMutation()
  const {
    data,
    refetch,
    isSuccess: isSuccessQuery,
    isLoading: isPendingQuery,
    isFetching: isFetchingQuery,
    error: errorQuery,
  } = common.useDictionaryQuery()
  const { setAuthToken, setDictionary, setAccountType } = useSession()
  const isRecaptchaPassed = useRecaptchaPassed()
  const getSign = async () => {
    try {
      const $meta = document.querySelector('meta[name=sign]')
      const content = JSON.parse($meta?.getAttribute('content') as string)
      console.log('current signature: %o', content)
      if (!content.signature) {
        throw new Error('invalid sinature')
      }
      if (!content.timestamp) {
        throw new Error('invalid timestamp')
      }
      const accountType = (route.query.state ?? ('0' as string)).slice(-1) === '0' ? 'Individual' : 'Entity'

      const result = await mutateAsync({
        timestamp: content.timestamp.toString(),
        signature: content.signature,
        accountType: accountType,
        appId: content['APP-ID'],
        appKey: content['APP-KEY'],
        appUid: content['APP-UID'],
        appUrl: content['APP-URL'],
        code: route.query.code as string,
      })
      if (unref(isSuccessAuth)) {
        setAccountType(accountType)
        setAuthToken((result as any).access_token)
        await refetch()
        if (unref(isSuccessQuery)) {
          setDictionary(unref(data)?.data[0]!, t)
        }
      }
    } catch (err: any) {
      const retry =
        (err as Error).message?.includes('JSON') ||
        (err as Error).message?.startsWith('invalid') ||
        (err as Error).message?.startsWith('Timestamp')
      if (retry) {
        // Timestamp is not valid.
        isRecaptchaPassed.value = false
        window.location.reload()
      } else {
        toast.warning(err ? err.message : 'Internal Server Error')
      }
    }
  }

  const isSuccess = computed(() => unref(isSuccessAuth) && unref(isSuccessQuery))
  const isPending = computed(() => unref(isPendingAuth) || unref(isPendingQuery))
  const isFetching = computed(() => unref(isPendingAuth) || unref(isFetchingQuery))
  const error = computed(() => unref(errorAuth) || unref(errorQuery))
  return {
    isPending,
    isSuccess,
    isFetching,
    error,
    getSign,
  }
}
