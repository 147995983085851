<template>
  <footer class="tw-bg-[#111224]/[.02]">
    <section class="tw-container tw-mx-auto tw-py-8">
      <div class="">
        <NuxtLinkLocale to="/">
          <SvgoMenuHeaderLogo class="tw-h-[30px] md:tw-h-10 tw-fill-[#111224]" />
        </NuxtLinkLocale>
      </div>
      <div class="tw-h-px tw-bg-[#F1F1F1] tw-my-7"></div>
      <section class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-y-7">
        <nav v-for="(item, index) in menuList" :key="index">
          <h6 class="tw-font-bold tw-leading-7">{{ $t(item.title) }}</h6>
          <div class="tw-gap-y-1 tw-flex tw-flex-col tw-mt-2">
            <div v-for="(menu, subIndex) in item.list" class="tw-flex tw-items-center tw-cursor-pointer" :key="subIndex">
              <div v-if="menu.title === 'View all'">
                <NuxtLinkLocale :to="menu.href" class="text-highlight">{{ $t(menu.title) }}</NuxtLinkLocale>
              </div>
              <div v-else-if="menu.title === 'Support help center'" @click="navigateTo(menu.href)">
                {{ $t(menu.title) }}
              </div>
              <div v-else-if="menu.key === 'twitter'" class="tw-flex tw-items-center" @click="navigateTo(menu.href)">
                <SvgoMenuX class="tw-w-5 tw-h-5 tw-mr-2" />
                {{ $t(menu.title) }}
              </div>
              <div v-else-if="menu.title === 'LinkedIn'" class="tw-flex tw-items-center" @click="navigateTo(menu.href)">
                <SvgoMenuLinkedIn class="tw-w-5 tw-h-5 tw-mr-2" />
                {{ $t(menu.title) }}
              </div>
              <div v-else-if="menu.title === 'Contact us'" @click="onTouched">
                {{ $t(menu.title) }}
              </div>
              <div v-else>
                <NuxtLinkLocale :to="menu.href">{{ $t(menu.title) }}</NuxtLinkLocale>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </section>
  </footer>
  <footer class="tw-bg-primary tw-text-white tw-flex tw-flex-col tw-gap-3 tw-py-5 md:tw-py-7">
    <div class="tw-container tw-mx-auto tw-flex tw-flex-col tw-justify-between tw-items-center md:tw-items-start tw-text-sm tw-font-normal">
      <strong class="tw-mr-2">Risk Warning</strong>
      Cryptocurrency trading is high-risk and may result in the loss of some or all funds. Trade responsibly.
    </div>
    <div class="tw-container tw-mx-auto tw-flex tw-flex-col tw-justify-between tw-items-center md:tw-items-start tw-text-sm tw-font-normal">
      Legend Holdings US Inc. Registered No. 7477583. Registered Office: 251 Little Falls Drive, Wilmington, DE 19808.
    </div>
    <div
      class="tw-container tw-mx-auto tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-text-sm tw-gap-2 tw-leading-relaxed tw-font-normal"
    >
      <p> © {{ new Date().getFullYear() }} {{ $t('legend_trading_dot') }} {{ $t('All rights reserved.') }} </p>
      <p>
        <NuxtLinkLocale class="tw-text-white" to="/terms"> {{ $t('Terms of Use') }} </NuxtLinkLocale>
        |
        <NuxtLinkLocale class="tw-text-white" to="/policy">{{ $t('Privacy Policy') }}</NuxtLinkLocale>
      </p>
    </div>
  </footer>
</template>
<script lang="ts" setup>
  const { isZa } = useZA()
  const menuList = computed(() => [
    ...(unref(isZa)
      ? [
          {
            title: 'Products',
            list: [
              {
                title: 'Legend OTC',
                href: '/legend-otc',
              },
              {
                title: 'Trade with us',
                href: '/kyc',
              },
            ],
          },
        ]
      : [
          {
            title: 'Products',
            list: [
              {
                title: 'Legend OTC',
                href: '/legend-otc',
              },
              {
                title: 'Legend Pay',
                href: '/legend-pay',
              },
              {
                title: 'Showcases',
                href: `/cases`,
              },
              {
                title: 'Trade with us',
                href: '/kyc',
              },
            ],
          },
          {
            title: 'Showcases',
            list: [
              {
                title: 'KuCoin',
                href: `/cases/kucoin`,
              },
              {
                title: 'Phemex',
                href: `/cases/phemex`,
              },
              {
                title: 'View all',
                href: `/cases`,
              },
            ],
          },
          {
            title: 'Developers',
            list: [
              {
                title: 'Guidelines & Code',
                href: `/developers`,
              },
            ],
          },
        ]),

    {
      title: 'Company',
      list: [
        {
          title: 'About us',
          href: `/about`,
        },
        {
          title: 'Media kit',
          href: '/media-kit',
        },
        {
          title: 'Become our partner',
          href: `/partner`,
        },
        {
          title: 'Support help center',
          href: 'https://support.legendtrading.com/hc/en-us',
        },
        ...(unref(isZa)
          ? []
          : [
              {
                title: 'Blog',
                href: 'https://support.legendtrading.com/hc/en-us/sections/1260801558890-Announcements',
              },
            ]),
        {
          title: 'Newsroom',
          href: `/newsroom`,
        },
      ],
    },
    {
      title: 'Follow',
      list: [
        {
          key: 'twitter',
          title: 'X(Twitter)',
          href: 'https://x.com/LegendOTC',
        },
        {
          title: 'LinkedIn',
          href: 'https://www.linkedin.com/company/legend-trading',
        },
        {
          title: 'Contact us',
          href: '',
        },
      ],
    },
  ])
  const zenDesk = useZenDesk()
  const onTouched = () => {
    zenDesk.onToggle()
  }

  const navigateTo = (herf: string) => {
    window.open(herf)
  }
</script>
