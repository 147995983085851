import type { UseFetchOptions } from '#app'

import { toast } from 'vue3-toastify'

export type ErrorT = {
  code: string
  message: string
  success: 'success' | 'error'
  errors: Record<string, any>[]
}

export interface DataT<T> {
  data: T
  code: string
  message: string
  success: 'success' | 'error'
}

export type OptionT<T> = Omit<UseFetchOptions<DataT<T>>, 'method' | 'query' | 'body' | 'params' | 'headers' | 'baseURL'> & {
  injectToast?: boolean
}

const handerResponse = (injectToast: boolean) => {
  const session = useSession()
  return {
    onRequest({ options }: any) {
      options.headers = new Headers(options.headers)
      // options.headers.set("Accept", "application/json");
      const token = session.getAuthToken()
      if (token) {
        options.headers.set('Authorization', `Bearer ${token}`)
      }
    },
    // 响应拦截
    onResponse({ response }: any) {
      if (response._data && response._data.status && import.meta.client) {
        const state = response._data.status
        const code = response._data.code
        if (state === 'error') {
          const _data = response._data
          if (import.meta.client && injectToast) {
            toast.warning(response._data.message)
          }
          return Promise.reject(_data)
        } else if (state === 'success') {
        }
      }
      // 成功返回
      return response._data
    },
    // 错误处理
    onResponseError({ response }: any) {
      if (import.meta.client && injectToast) {
        console.log(123)
        toast.error(response._data.message)
      } 
      return Promise.reject(response._data)
    },
  }
}

async function fetch<D extends Record<string, any>>(url: string, option: UseFetchOptions<DataT<D>> & { injectToast?: boolean }) {
  const { injectToast, ..._options } = option

  const handerOptions = handerResponse(injectToast ?? true)

  return $fetch<DataT<D>>(url, {
    // @ts-ignore
    baseURL: `https://${import.meta.env.VITE_API_HOST}`, //'/api/test'
    ..._options,
    ...handerOptions,
  })
}

export async function fetchByGet<D extends {}>(url: string, params?: Record<string, any>, options?: OptionT<D>) {
  return fetch<D>(url, {
    method: 'GET',
    params,
    headers: {
      Accept: 'application/json',
    },
    ...options,
  })
}

export async function fetchByPost<D extends {}>(url: string, body?: Record<string, any>, options?: OptionT<D>) {
  return fetch<D>(url, {
    method: 'POST',
    body,
    headers: {
      Accept: 'application/json',
    },
    ...options,
  })
}

export async function fetchByPut<D extends {}>(url: string, body?: Record<string, any>, options?: OptionT<D>) {
  return fetch<D>(url, {
    method: 'PUT',
    body,
    headers: {
      Accept: 'application/json',
    },
    ...options,
  })
}

export async function fetchByPatch<D extends {}>(url: string, body?: Record<string, any>, options?: OptionT<D>) {
  return fetch<D>(url, {
    method: 'PATCH',
    body,
    headers: {
      Accept: 'application/json',
    },
    ...options,
  })
}

export async function fetchByDelete<D extends {}>(url: string, body?: Record<string, any>, options?: OptionT<D>) {
  return fetch<D>(url, {
    method: 'DELETE',
    body,
    headers: {
      Accept: 'application/json',
    },
    ...options,
  })
}

export async function fetchByFile<D extends {}>(url: string, body: FormData, options?: OptionT<D>) {
  return fetch<D>(url, {
    method: 'POST',
    body: body,
    headers: {
      Accept: 'application/json',
      // "Content-Type": "multipart/form-data",
    },
    ...options,
  })
}
